<script setup lang="ts">
import { ref } from 'vue';
import { useField } from 'vee-validate';
import { useLoganStore } from '~/stores/logan';

import { FormControl, FormItem, FormField, Input } from '@laam/ui/base';

const LoganStore = useLoganStore();

interface OtpInput {
	error: boolean;
}
defineProps<OtpInput>();
const code = ref('');

const otpCodeField = useField('otpCode');

const handleInput = (val: string) => {
	code.value = val;
	otpCodeField.setValue(val);

	//  allow only digits from 1 to 9
	if (!/^[0-9]*$/.test(code.value)) {
		otpCodeField.setValue(val);
		return;
	}
	// if input box value is not empty string and we are not at last input box
	if (code.value !== '') {
		otpCodeField.setValue(val);
	}
};

// function to handle otp code paste
const handlePaste = (e: any, val: string) => {
	const pasteData = e.clipboardData.getData('text');
	code.value = val;
	otpCodeField.setValue(pasteData);
};

watch(code, (newValue) => {
	otpCodeField.setValue(newValue);
});

watch(
	() => LoganStore.alertMessage,
	(n) => {
		if (n === 'Invalid Code') {
			code.value = '';
			otpCodeField.setValue('');
		}
	},
);
</script>

<template>
	<div class="flex justify-between items-center w-full mt-1">
		<FormField v-slot="{ componentField }" name="otpCode" class="w-full">
			<FormItem class="w-full">
				<div class="phone_input_container flex flex-col gap-sm w-full">
					<FormControl>
						<div class="flex items-center justify-start w-full">
							<div class="flex-[9] justify-center">
								<Input
									v-model="code"
									placeholder="------"
									v-bind="componentField"
									maxlength="6"
									style="font-size: 16px; text-align: center"
									autocomplete="one-time-code"
									class="text-center w-full otp_input_form"
									:class="{
										'border-error-500 focus-within:ring-0':
											LoganStore.alertMessage === 'Invalid Code',
									}"
									:style="{
										letterSpacing: isMobileView() ? '2em' : '3em',
										paddingLeft: isMobileView() ? '28px' : '50px',
									}"
									data-testid="otp-input"
									@input="handleInput(componentField.modelValue)"
									@paste="handlePaste($event, componentField.modelValue)"
								/>
							</div>
						</div>
						<p
							v-if="LoganStore.alertMessage === 'Invalid Code'"
							class="logan_phone_error text-error-500 text-sm text-center"
						>
							Invalid Code entered
						</p>
					</FormControl>
				</div>
			</FormItem>
		</FormField>
	</div>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
